<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0" >
        <span @click="$router.go(-1)" >
          <b-button
              variant="white"
              class="btn-icon rounded-circle mr-50 text-danger bg-white"
              size="sm"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span> Datos empresa
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="editConfig">
        <b-form
          class="mt-2"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col sm="6">
              <b-form-group
                  :label="'Nombre empresa'"
                  label-for="account-name"
              >
                <b-form-input
                    v-model="company_name"
                    name="company_name"
                    :placeholder="'Nombre empresa'"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6" >
              <b-form-group
                  :label="'Dirección'"
                  label-for="account-direction"
              >
                <b-form-input
                    v-model="direction"
                    :placeholder="'Dirección'"
                    name="direction"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <validation-provider
                  #default="{ errors }"
                  :name="'Email'"
                  rules="required|email"
              >
                <b-form-group
                    :label="'Email'"
                    label-for="account-e-mail"
                >
                  <b-form-input
                      v-model="email"
                      name="email"
                      :placeholder="'Email'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <b-form-group
                  :label="'Telefono'"
                  label-for="account-phone"
              >
                <b-form-input
                    v-model="phone"
                    name="phone"
                    :placeholder="'Telefono'"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                  :label="'CIF'"
                  label-for="account-cif"
              >
                <b-form-input
                    v-model="cif"
                    name="cif"
                    :placeholder="'CIF'"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12 text-right">
              <b-button
                  type="submit"
                  variant="primary"
                  class="mt-2 mr-1"
              >
                {{ 'Guardar' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BRow, BCol, BCard, BFormInput
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      company_name: '',
      phone: '',
      email: '',
      direction: '',
      cif: '',
    }
  },
  computed: {
    ...mapGetters({
      config: 'config/getConfig',
    }),
  },
  methods: {
    ...mapActions({
      edit: 'config/edit',
      getConfig: 'config/getConfig',
    }),
    handleSubmit() {
      this.$refs.editConfig.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.edit({ config: formData })
          }
        }
      })
    },
    setData() {
      this.company_name = this.config.name
      this.email = this.config.email
      this.phone = this.config.phone
      this.direction = this.config.direction
      this.cif = this.config.cif
    },
    createFormData() {
      const data = new FormData()
      data.append('name', this.company_name)
      data.append('email', this.email)
      data.append('phone', this.phone)
      data.append('direction', this.direction)
      data.append('cif', this.cif)

      return data
    },
  },
  async created() {
    await this.getConfig()
    await this.setData()
  },
}
</script>
